import { lazy, Suspense } from "react";

const OrganizationPage = lazy(() => import("pages/OrganizationPage"));
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));

export const sharedRoutes = [
    {
        path: "organization/:name",
        element: <Suspense fallback={<></>}><OrganizationPage /></Suspense>,
        children: [],
    },
    {
        path: "*",
        element: <Suspense fallback={<></>}><NotFoundPage /></Suspense>,
        children: [],
    }
];